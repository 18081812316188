import { MDBContainer } from "mdb-react-ui-kit";
import React, { useMemo } from "react";
import {
    ReviewReadableTO,
    TBreackpointsCarousel,
} from "../../models/common/common";
import styles from "../../styles/home/Home.TourReview.module.scss";
import {
    tourReviewSubtitle,
    tourReviewTitle,
} from "../../utils/mockData/homeMocks";
import { randomUid } from "../../utils/randomIds";
import CardReview from "./cards/CardReview";

import SectionCarouselLayout from "../common/SectionCarouselLayout";

const TourReview: React.FC<{ reviewContents: ReviewReadableTO[] }> = ({
    reviewContents,
}) => {
    const itemList = useMemo(
        () =>
            reviewContents.map((item: ReviewReadableTO) => ({
                value: item,
                id: randomUid(),
            })),
        [reviewContents]
    );

    // const title: ISectionTitle = {
    //     title: tourReviewTitle,
    //     titleColumnSize: 10,
    //     alignment: "justify-content-md-center text-md-left",
    //     mobileTitleColumnSize: 10,
    //     classNameContainer: "py-3",
    // };

    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1.1,
            height: 533,
            spaceBetween: 10,
        },
        576: {
            slidesPerView: 2,
            height: 533,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 2,
            height: 533,
            spaceBetween: 12,
        },
        992: {
            slidesPerView: 2,
            height: 533,
            spaceBetween: 12,
        },
        1200: {
            slidesPerView: 2,
            height: 533,
            spaceBetween: 32,
        },
        1400: {
            slidesPerView: 3,
            spaceBetween: 32,
            height: 400,
        },
        1600: {
            slidesPerView: 3,
            spaceBetween: 32,
            height: 400,
        },
        1920: {
            slidesPerView: 3,
            spaceBetween: 32,
            height: 568,
        },
    };

    return (
        <MDBContainer
            id="tour-reviews"
            fluid
            className={`${styles.container} align-items-center py-5 px-0`}
        >
            {/* <MDBRow 
                className="pb-2 pb-md-3 px-0"
                style={{ textAlign: "start" }}
            >
                <SectionTitle sectionTitle={title} />
            </MDBRow> */}
            <SectionCarouselLayout
                visibleSlides={3}
                sectionTitle={tourReviewTitle}
                sectionSubTitle={tourReviewSubtitle}
                classNameSlide={styles.tourReviewsSlide}
                breakpointsCarousel={breackPointCarousel}
                centerInsufficientSlides
                isCarouselContained
            >
                {itemList.map(el => (
                    <React.Fragment key={el.id}>
                        <CardReview content={el.value} />
                    </React.Fragment>
                ))}
            </SectionCarouselLayout>
        </MDBContainer>
    );
};
export default TourReview;
